import React from "react";

function Footer() {
  return (
    <footer className="pt-4 pb-4 text-muted text-center d-print-none footer">
      <div className="containerfooter">
        <div className="my-3">
          <div className="footer-nav">
            <nav role="navigation">
              <ul className="nav justify-content-center">
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://github.com/Edmond-Jh"
                    title="Github"
                    target="_blank"
                  >
                    <i className="fab fa-github"></i>
                    <span className="menu-title sr-only">Github</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://www.linkedin.com/in/mohamad-hossein-nazari-7b7590225/"
                    title="Linkedin"
                    target="_blank"
                  >
                    <i className="fab fa-linkedin"></i>
                    <span className="menu-title sr-only">Linkedin</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Layout from "./Components/Layout/Layout";
import Persian from "./Components/Persian/Persian";
import English from "./Components/English/English";
import Russian from "./Components/Russian/Russian";

import "./Css/site.css";
import "./Css/print.css";
import "./Css/bootstrap.min.css";
import "./Css/bootstrap.css";
import "./Css/main.css";

function App() {
  return (
    <Layout>
      <Routes>
        <Route path="/" exact element={<English />} />
        <Route path="/russian" exact element={<Russian />} />
        <Route path="/persian" exact element={<Persian />} />
        <Route path="*" exact element={<English />} />
      </Routes>
    </Layout>
  );
}

export default App;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Layout.css";

function Header() {
  // const [theme, setTheme] = useState();
  // function darkmode(data) {

  //   if (data.target.checked || theme?.target.checked) {
      
  //     let pagecontent = document.querySelector(".page-content");
  //     let header = document.querySelector(".d-print-none");
  //     let footer = document.querySelector(".footer");
  //     let element = document.querySelector(".bg-whites");
  //     let card = document.querySelectorAll(".card-body");
  //     let coverElement = document.getElementById("myCover");

  //     header.style.backgroundColor = "black";
  //     footer.style.backgroundColor = "black";
  //     pagecontent.style.backgroundColor = "black";
  //     coverElement.style.border = "2px solid green";
  //     pagecontent.style.color = "white";
  //     element.style.backgroundColor = "black !important";
  //     card.forEach(function (card) {
  //       card.style.backgroundColor = "black";
  //     });

  //   } else {

  //     let pagecontent = document.querySelector(".page-content");
  //     let header = document.querySelector(".d-print-none");
  //     let footer = document.querySelector(".footer");
  //     let element = document.querySelector(".bg-whites");
  //     let card = document.querySelectorAll(".card-body");
  //     let coverElement = document.getElementById("myCover");

  //     pagecontent.style.backgroundColor = "white";
  //     coverElement.style.border = "";
  //     header.style.backgroundColor = "white";
  //     footer.style.backgroundColor = "white";
  //     pagecontent.style.color = "black";
  //     element.style.backgroundColor = "white !important";
  //     card.forEach(function (card) {
  //       card.style.backgroundColor = "white";
  //     });
  //   }
  // }
  return (
    <header className="d-print-none">
      <div className="container text-center text-lg-left">
        <div className="navbarlang py-3 clearfix">
          <Link to="/english" className="LinkTo">
            English
          </Link>
          <Link to="/russian" className="LinkTo">
            Русский
          </Link>
          <Link to="/persian" className="LinkTo">
            فارسی
          </Link>
        </div>
        <div className="py-3 clearfix">
          <div className="site-nav">
            <nav role="navigation">
              <ul className="nav justify-content-center">
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://github.com/Edmond-Jh"
                    title="Github"
                    target="_blank"
                  >
                    <i className="fab fa-github"></i>
                    <span className="menu-title sr-only">Github</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://www.linkedin.com/in/mohamad-hossein-nazari-7b7590225/"
                    title="Linkedin"
                    target="_blank"
                  >
                    <i className="fab fa-linkedin"></i>
                    <span className="menu-title sr-only">LinkedIn</span>
                  </a>
                </li>
                {/* <li className="nav-item">
                  <div className="content">
                    <i className="fas fa-sun"></i>
                    &nbsp;&nbsp;&nbsp;
                    <input
                      type="checkbox"
                      id="tooglenight"
                      className="cbx hidden"
                      onClick={darkmode}
                      onChange={setTheme}
                    />
                    <label htmlFor="tooglenight" className="switch"></label>
                    &nbsp;&nbsp;&nbsp;
                    <i className="fas fa-moon"></i>
                  </div>
                </li> */}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
}
export default Header;

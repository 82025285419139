import React from "react";

function Persian() {
  return (
    <div className="page-content">
      <div className="container">
        <div className="cover shadow-lg bg-whites" id="myCover">
          <div className="cover-bg p-3 p-lg-4 text-white">
            <div className="row">
              <div className="col-lg-4 col-md-5">
                <div className="avatar hover-effect bg-white shadow-sm p-1 fade-in-zoom">
                  <img src="./image/Avatar.jpg" width="200" height="200" />
                </div>
              </div>
              <div className="col-lg-8 col-md-7 text-center text-md-start">
                <h2 className="h1 mt-2  fade-in-right3 hover-effect">
                  Mohamad Hossein Nazari
                </h2>
                <p className="fade-in-right2 hover-effect">
                  FrontEnd Developer
                </p>
                <div className="d-print-none">
                  {/* <a
                    className="btn btn-light text-dark shadow-sm mt-1 me-1  fade-in-right"
                    href="./cvdownload/MyResume.pdf"
                    target="_blank"
                    download
                  >
                    Download CV
                  </a> */}
                  <a
                    className="btn btn-success shadow-sm mt-1 fade-in-right2"
                    href="#contact"
                  >
                    Make Contact!
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="about-section pt-4 px-3 px-lg-4 mt-1">
            <div className="row">
              <div className="col-md-6">
                <h2 className="h3 mb-3">About Me</h2>
                <p
                  style={{
                    textAlign: "center",
                    fontFamily: "Segoe UI, Tahoma, Geneva, Verdana, sans-serif",
                  }}
                >
                  <h5> محمد حسین نظری</h5>
                  من یک دانشجوی مهندسی نرم‌افزار کامپیوتر در دانشگاه رودن مسکو
                  هستم، که از دوران دبیرستان علاقه‌ی شدیدی به برنامه‌نویسی دارم.
                  در زمینه توسعه ری‌اکت تخصص دارم و متعهد به ایستادن در جلوی
                  فناوری و همکاری در پروژه‌های نوآورانه هستم. در محیط‌های چالشی
                  موفق هستم و از فناوری برای حل مسائل واقعی و ایجاد تجارب
                  دیجیتال مؤثر استفاده می‌کنم.
                </p>
              </div>
              <div className="col-md-5 offset-md-1">
                <div className="row mt-2">
                  <div className="col-sm-4">
                    <div className="pb-1">Age</div>
                  </div>
                  <div className="col-sm-8">
                    <div className="pb-1 ">22</div>
                  </div>
                  <div className="col-sm-4">
                    <div className="pb-1">Email</div>
                  </div>
                  <div className="col-sm-8">
                    <div className="pb-1 ">
                      MohamadHossein.Nazari@yahoo.com
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="pb-1">Address</div>
                  </div>
                  <div className="col-sm-8">
                    <div className="pb-1 ">روسیه - مسکو</div>
                  </div>
                </div>
                <div className="container2">
                  <svg
                    className="ghost"
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="127.433px"
                    height="132.743px"
                    viewBox="0 0 127.433 132.743"
                    enableBackground="new 0 0 127.433 132.743"
                  >
                    <path
                      fill="#138911"
                      d="M116.223,125.064c1.032-1.183,1.323-2.73,1.391-3.747V54.76c0,0-4.625-34.875-36.125-44.375
                      s-66,6.625-72.125,44l-0.781,63.219c0.062,4.197,1.105,6.177,1.808,7.006c1.94,1.811,5.408,3.465,10.099-0.6
                      c7.5-6.5,8.375-10,12.75-6.875s5.875,9.75,13.625,9.25s12.75-9,13.75-9.625s4.375-1.875,7,1.25s5.375,8.25,12.875,7.875
                      s12.625-8.375,12.625-8.375s2.25-3.875,7.25,0.375s7.625,9.75,14.375,8.125C114.739,126.01,115.412,125.902,116.223,125.064z"
                    />
                    <circle fill="#000000" cx="86.238" cy="57.885" r="6.667" />
                    <circle fill="#000000" cx="40.072" cy="57.885" r="6.667" />
                    <path
                      fill="#000000"
                      d="M71.916,62.782c0.05-1.108-0.809-2.046-1.917-2.095c-0.673-0.03-1.28,0.279-1.667,0.771
	                    c-0.758,0.766-2.483,2.235-4.696,2.358c-1.696,0.094-3.438-0.625-5.191-2.137c-0.003-0.003-0.007-0.006-0.011-0.009l0.002,0.005
	                    c-0.332-0.294-0.757-0.488-1.235-0.509c-1.108-0.049-2.046,0.809-2.095,1.917c-0.032,0.724,0.327,1.37,0.887,1.749
                      c-0.001,0-0.002-0.001-0.003-0.001c2.221,1.871,4.536,2.88,6.912,2.986c0.333,0.014,0.67,0.012,1.007-0.01
                      c3.163-0.191,5.572-1.942,6.888-3.166l0.452-0.453c0.021-0.019,0.04-0.041,0.06-0.061l0.034-0.034
                      c-0.007,0.007-0.015,0.014-0.021,0.02C71.666,63.771,71.892,63.307,71.916,62.782z"
                    />
                    <circle
                      fill="#138911"
                      stroke="#138911"
                      strokeMiterlimit="10"
                      cx="18.614"
                      cy="99.426"
                      r="3.292"
                    />
                    <circle
                      fill="#138911"
                      stroke="#138911"
                      strokeMiterlimit="10"
                      cx="95.364"
                      cy="28.676"
                      r="3.291"
                    />
                    <circle
                      fill="#138911"
                      stroke="#138911"
                      strokeMiterlimit="10"
                      cx="24.739"
                      cy="93.551"
                      r="2.667"
                    />
                    <circle
                      fill="#138911"
                      stroke="#138911"
                      strokeMiterlimit="10"
                      cx="101.489"
                      cy="33.051"
                      r="2.666"
                    />
                    <circle
                      fill="#138911"
                      stroke="#138911"
                      strokeMiterlimit="10"
                      cx="18.738"
                      cy="87.717"
                      r="2.833"
                    />
                    <path
                      fill="#138911"
                      stroke="#138911"
                      strokeMiterlimit="10"
                      d="M116.279,55.814c-0.021-0.286-2.323-28.744-30.221-41.012
                      c-7.806-3.433-15.777-5.173-23.691-5.173c-16.889,0-30.283,7.783-37.187,15.067c-9.229,9.736-13.84,26.712-14.191,30.259
                      l-0.748,62.332c0.149,2.133,1.389,6.167,5.019,6.167c1.891,0,4.074-1.083,6.672-3.311c4.96-4.251,7.424-6.295,9.226-6.295
                      c1.339,0,2.712,1.213,5.102,3.762c4.121,4.396,7.461,6.355,10.833,6.355c2.713,0,5.311-1.296,7.942-3.962
                      c3.104-3.145,5.701-5.239,8.285-5.239c2.116,0,4.441,1.421,7.317,4.473c2.638,2.8,5.674,4.219,9.022,4.219
                      c4.835,0,8.991-2.959,11.27-5.728l0.086-0.104c1.809-2.2,3.237-3.938,5.312-3.938c2.208,0,5.271,1.942,9.359,5.936
                      c0.54,0.743,3.552,4.674,6.86,4.674c1.37,0,2.559-0.65,3.531-1.932l0.203-0.268L116.279,55.814z M114.281,121.405
                      c-0.526,0.599-1.096,0.891-1.734,0.891c-2.053,0-4.51-2.82-5.283-3.907l-0.116-0.136c-4.638-4.541-7.975-6.566-10.82-6.566
                      c-3.021,0-4.884,2.267-6.857,4.667l-0.086,0.104c-1.896,2.307-5.582,4.999-9.725,4.999c-2.775,0-5.322-1.208-7.567-3.59
                      c-3.325-3.528-6.03-5.102-8.772-5.102c-3.278,0-6.251,2.332-9.708,5.835c-2.236,2.265-4.368,3.366-6.518,3.366
                      c-2.772,0-5.664-1.765-9.374-5.723c-2.488-2.654-4.29-4.395-6.561-4.395c-2.515,0-5.045,2.077-10.527,6.777
                      c-2.727,2.337-4.426,2.828-5.37,2.828c-2.662,0-3.017-4.225-3.021-4.225l0.745-62.163c0.332-3.321,4.767-19.625,13.647-28.995
                      c3.893-4.106,10.387-8.632,18.602-11.504c-0.458,0.503-0.744,1.165-0.744,1.898c0,1.565,1.269,2.833,2.833,2.833
                      c1.564,0,2.833-1.269,2.833-2.833c0-1.355-0.954-2.485-2.226-2.764c4.419-1.285,9.269-2.074,14.437-2.074
                      c7.636,0,15.336,1.684,22.887,5.004c26.766,11.771,29.011,39.047,29.027,39.251V121.405z"
                    />
                  </svg>
                  <p className="shadowFrame">
                    <svg
                      version="1.1"
                      className="shadowAnimation"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      x="61px"
                      y="20px"
                      width="122.436px"
                      height="39.744px"
                      viewBox="0 0 122.436 39.744"
                      enableBackground="new 0 0 122.436 39.744"
                    >
                      <ellipse
                        fill="#5E6263"
                        cx="61.128"
                        cy="19.872"
                        rx="49.25"
                        ry="8.916"
                      />
                    </svg>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <hr className="d-print-none" />
          <div className="skills-section px-3 px-lg-4">
            <h2 className="h3 mb-3">Skills</h2>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-2">
                  <span>HTML</span>
                  <div className="progress my-1">
                    <div
                      className="progress-bar bg-primary fade-in-progressbar1"
                      role="progressbar"
                      style={{ width: "100%" }}
                      aria-valuenow="100"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      100%
                    </div>
                  </div>
                </div>
                <div className="mb-2">
                  <span>CSS</span>
                  <div className="progress my-1">
                    <div
                      className="progress-bar bg-primary fade-in-progressbar1"
                      role="progressbar"
                      style={{ width: "100%" }}
                      aria-valuenow="100"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      100%
                    </div>
                  </div>
                </div>
                <div className="mb-2">
                  <span>JavaScript</span>
                  <div className="progress my-1">
                    <div
                      className="progress-bar bg-primary fade-in-progressbar1"
                      role="progressbar"
                      style={{ width: "100%" }}
                      aria-valuenow="75"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      100%
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-2">
                  <span>React JS</span>
                  <div className="progress my-1">
                    <div
                      className="progress-bar bg-success fade-in-progressbar"
                      role="progressbar"
                      style={{ width: "95%" }}
                      aria-valuenow="95"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      90%
                    </div>
                  </div>
                </div>
                <div className="mb-2">
                  <span>TypeScript</span>
                  <div className="progress my-1">
                    <div
                      className="progress-bar bg-success fade-in-progressbar5"
                      role="progressbar"
                      style={{ width: "70%" }}
                      aria-valuenow="85"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      70%
                    </div>
                  </div>
                </div>
                <div className="mb-2">
                  <span>Next Js</span>
                  <div className="progress my-1">
                    <div
                      className="progress-bar bg-success fade-in-progressbar6"
                      role="progressbar"
                      style={{ width: "50%" }}
                      aria-valuenow="75"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      60%
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="d-print-none" />
          <div className="work-experience-section px-3 px-lg-4">
            <h2 className="h3 mb-4">Other Skills</h2>
            <div className="otherskills">
              <div className="col-md-6 tracks">
                <div className="mb-2">
                  <span>AntDesign</span>
                  <div className="progress my-1">
                    <div
                      className="progress-bar bg-dark"
                      style={{ width: "100%" }}
                    ></div>
                  </div>
                </div>
                <div className="mb-2">
                  <span>TailWind</span>
                  <div className="progress my-1">
                    <div
                      className="progress-bar bg-warning bg-danger"
                      style={{ width: "100%" }}
                    ></div>
                  </div>
                </div>
                <div className="mb-2">
                  <span>Material UI</span>
                  <div className="progress my-1">
                    <div
                      className="progress-bar bg-warning"
                      style={{ width: "100%" }}
                    ></div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 tracks">
                <div className="mb-2">
                  <span>Redux</span>
                  <div className="progress my-1">
                    <div
                      className="progress-bar bg-dark"
                      style={{ width: "100%" }}
                    ></div>
                  </div>
                </div>
                <div className="mb-2">
                  <span>Bootstrap</span>
                  <div className="progress my-1">
                    <div
                      className="progress-bar bg-danger"
                      style={{ width: "100%" }}
                    ></div>
                  </div>
                </div>
                <div className="mb-2">
                  <span>Sass</span>
                  <div className="progress my-1">
                    <div
                      className="progress-bar bg-warning"
                      style={{ width: "100%" }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="d-print-none" />
          <div className="work-experience-section px-3 px-lg-4">
            <h2 className="h3 mb-4">Work Experience</h2>
            <div className="timeline">
              <div className="timeline-card timeline-card-primary card shadow-sm">
                <div className="card-body">
                  <div className="h5 mb-1">
                    Frontend Developer{" "}
                    <span className="text-muted h6">Mapsa Hr</span>
                  </div>
                  <div className="text-muted text-small mb-2">1399 - 1400</div>
                  <div className="Font-Persian">
                    در طول دوره کارآموزی من در شرکت مپسا، مهارت‌هایم در زمینه
                    جاوااسکریپت و کدنویسی از طریق جلسات آموزشی جامع، بهبود یافت.
                    من به طور فعال در یادگیری مبتنی بر پروژه شرکت کردم و
                    توانایی‌هایم در
                    <br />
                    React , TypeScript , Next.js
                    <br />
                    را به دست آوردم. این تجربه نه‌تنها توانایی‌های فنی‌ام را
                    بهبود بخشید، بلکه همچنین همکاری تیمی و همکاری مبتنی بر پروژه
                    را تقویت کرد
                  </div>
                </div>
              </div>
              <div className="timeline-card timeline-card-primary card shadow-sm">
                <div className="card-body">
                  <div className="h5 mb-1">
                    FreeLance
                    <span className="text-muted h6"></span>
                  </div>
                  <div className="text-muted text-small mb-2">
                    1397 - تا به امروز
                  </div>
                  <div className="Font-Persian">
                    از دوران دبیرستان، به‌طور فعال در کارهای آزاد مشغول بوده‌ام
                    و در ابتدا وارد عرصه برنامه‌نویسی شده و تمرکز خود را بر روی
                    توسعه وب با استفاده از ری اکت گذاشته‌ام. با گذشت زمان،
                    علاقه‌ی خاصی به طراحی رابط کاربری و تجربه کاربری برای
                    برنامه‌های وبی و سرورهای بازی پیدا کرده‌ام. سفر آزاد به من
                    امکان اکتشاف پروژه‌های متنوع و بهبود مهارت‌هایم در زمینه‌های
                    مختلف را فراهم کرده است که به درک جامع‌تری از توسعه و طراحی
                    وب کمک کرده است
                  </div>
                </div>
              </div>
              <div className="timeline-card timeline-card-primary card shadow-sm">
                <div className="card-body">
                  <div className="h5 mb-1">
                    Gaming UI
                    <span className="text-muted h6"></span>
                  </div>
                  <div className="text-muted text-small mb-2">1402</div>
                  <div className="Font-Persian">
                    به طراحی رابط کاربری جدیدی برای سرور ریج مولتی پلیر پرداختم.
                    طراحی‌های رابط کاربری را در گیت‌هاب به‌روزرسانی کردم تا
                    تجربه کاربری در جامعه بازی‌کنان بهبود یابد
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="d-print-none" />
          <div className="page-break"></div>
          <div className="education-section px-3 px-lg-4 pb-4">
            <h2 className="h3 mb-4">Education</h2>
            <div className="timeline">
              <div className="timeline-card timeline-card-success card shadow-sm">
                <div className="card-body">
                  <div className="h5 mb-1">
                    IPC, Machine Learning and Cybersecurity
                  </div>
                  <div className="text-muted text-small mb-2">1402</div>
                  <div className="Font-Persian">
                    در حال تحصیل در رشته کنترل فرآیندهای اطلاعات، یادگیری ماشین،
                    و امنیت سایبری در دانشگاه رودن مسکو. در حال کاوش در
                    متدولوژی‌های پیشرفته کنترل که ماشین یادگیری و پروتکل‌هایی که
                    امنیت سایبری را بهینه‌سازی می‌کند و خطرات را کاهش می‌دهد
                  </div>
                </div>
              </div>
              <div className="timeline-card timeline-card-success card shadow-sm">
                <div className="card-body">
                  <div className="h5 mb-1">مهندسی کامپیوتر</div>
                  <div className="text-muted text-small mb-2">1399</div>
                  <div className="Font-Persian">
                    مدرک کارشناسی مهندسی نرم‌افزار را از دانشگاه آزاد ایران کسب
                    کردم. این تجربه به من امکان فراگیری مفاهیم اساسی و پیشرفته
                    در زمینه‌ی توسعه و مهندسی نرم‌افزار را فراهم نمود و من را
                    برای فعالیت‌های شغلی در این حوزه آماده کرد.
                  </div>
                </div>
              </div>
              <div className="timeline-card timeline-card-success card shadow-sm">
                <div className="card-body">
                  <div className="h5 mb-1">دیپلم کامپیوتر</div>
                  <div className="text-muted text-small mb-2">1396 - 1399</div>
                  <div className="Font-Persian">
                    از دبیرستان، دیپلم کامپیوتر برجسته‌ای با میانگین نمره ۱۸.۵۲
                    دریافت کردم. در کدنویسی با زبان‌های سی شارپ و سی پلاس پلاس
                    مهارت‌های لازم را به دست آوردم و دانش پایه‌ای در زمینه
                    ماشین‌های مجازی و اصول شبکه کسب کردم. همچنین، با شروع آموزش
                    اچ تی ام ال و سی اس اس و مسلط شدن به اصول
                    <br />
                    HTML,CSS,
                    <br />
                    به مطالعه‌ی عمیق در توسعه وردپرس پرداختم
                  </div>
                </div>
              </div>
              <div className="timeline-card timeline-card-success card shadow-sm">
                <div className="card-body">
                  <div className="h5 mb-1">دوره ی برنامه نویسی وب</div>
                  <div className="text-muted text-small mb-2">1399 - 1400</div>
                  <div className="Font-Persian">
                    با موفقیت دوره جامع برنامه‌نویسی وب را به پایان رساندم و
                    گواهی مهارت دریافت کردم. در زمینه
                    <br />
                    React.Js , TypeScript , Next.Js
                    <br />
                    تخصص پیدا کردم و دانش عمیق و مهارت‌های عملی خود را در
                    چارچوب‌های مدرن توسعه وب به دست آوردم
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="d-print-none" />
          <div className="project-section px-3 px-lg-4 pb-4">
            <h2 className="h3 mb-4">Project</h2>
            <div className="timeline">
              <div className="timeline-card timeline-card-success card shadow-sm">
                <div className="card-body">
                  <div className="h5 mb-1">FetNlix</div>
                  <div className="text-muted text-small mb-2">Website</div>
                  <div className="Font-Persian">
                    وب‌سایت فت‌نلیکس را به صورت مستقل توسعه دادم، از تخصص خود در
                    زمینه توسعه وب بهره بردم. برای مدیریت وضعیت، از ری‌اکت و
                    ریداکس استفاده کردم تا به دستکاری کارایی مدیریت داده‌ها
                    بپردازم. علاوه بر این، سیستم توستیفای را برای اطلاع‌رسانی به
                    کاربران ادغام کردم و به‌روزرسانی‌های پویا ای پی آی را برای
                    پاسخگویی به زمان واقعی پیاده‌سازی کردم
                  </div>
                  <img src="./image/fetnlix.jpg" alt="FetNlix" className="portal-image"></img>
                  <a
                    className="project-button btn shadow-sm mt-1 me-1 "
                    href="https://fetnelix.vercel.app/"
                    target="_blank"
                  >
                    Go To Website!
                  </a>
                </div>
              </div>
              <div className="timeline-card timeline-card-success card shadow-sm">
                <div className="card-body">
                  <div className="h5 mb-1">World War2</div>
                  <div className="text-muted text-small mb-2">Website</div>
                  <div className="Font-Persian">
                    وب‌سایت تاریخی جهانی جنگ جهانی دوم را سفارشی‌سازی کردم، با
                    ادغام یک ای پی آی سفارشی که با استفاده از یک پلتفرم وب ایجاد
                    کرده بودم. در طراحی و توسعه وب‌سایت، سعی کردم از زیبایی‌های
                    سی‌اس‌اس بهره ببرم، تا تجربه بصری کاربران را بهبود بخشم. این
                    وب‌سایت ویژگی‌های سفارشی را برای بهبود تجربه کاربری و ارائه
                    محتوای پویا ادغام کرده است
                  </div>
                  <img src="./image/worldwar2.jpg" alt="worldwar2" className="portal-image"></img>
                  <a
                    className="project-button btn shadow-sm mt-1 me-1 "
                    href="https://world-war2.vercel.app/"
                    target="_blank"
                  >
                    Go To Website!
                  </a>
                </div>
              </div>
            </div>
          </div>
          <hr className="d-print-none" />
          <div className="education-section px-3 px-lg-4 pb-4">
            <h2 className="h3 mb-4">speaking languages</h2>
            <div className="otherskills">
              <div className="col-md-6 tracks">
                <div className="mb-2">
                  <span>پارسی</span>
                  <div className="progress my-1">
                    <div
                      className="progress-bar bg-dark"
                      style={{ width: "100%" }}
                    >
                      زبان مادری
                    </div>
                  </div>
                </div>
                <div className="mb-2">
                  <span>روسی</span>
                  <div className="progress my-1">
                    <div
                      className="progress-bar bg-dark"
                      style={{ width: "100%" }}
                    >
                      متوسط
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 tracks">
                <div className="mb-2">
                  <span>انگلیسی</span>
                  <div className="progress my-1">
                    <div
                      className="progress-bar bg-dark"
                      style={{ width: "100%" }}
                    >
                      حرفه ای
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="d-print-none" />
          <div className="contant-section px-3 px-lg-4 pb-4" id="contact">
            <h2 className="h3 text mb-3">Contact</h2>
            <div className="row">
              <div className="col-md-7 d-print-none">
                <div className="my-2">
                  <form action="https://formspree.io/f/mgedpayv" method="POST">
                    <div className="row">
                      <div className="col-6">
                        <input
                          className="form-control"
                          type="text"
                          id="name"
                          name="name"
                          placeholder="Your Name"
                          required
                        />
                      </div>
                      <div className="col-6">
                        <input
                          className="form-control"
                          type="email"
                          id="email"
                          name="_replyto"
                          placeholder="Your E-mail"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group my-2">
                      <textarea
                        className="form-control"
                        style={{ resize: "none" }}
                        id="message"
                        name="message"
                        rows="4"
                        placeholder="Your Message"
                        required
                      ></textarea>
                    </div>
                    <button className="btn btn-primary mt-2" type="submit">
                      Send
                    </button>
                  </form>
                </div>
              </div>
              <div className="col">
                <div className="mt-2">
                  <h3 className="h6">Address</h3>
                  <div className="pb-2 ">مسکو</div>
                  <h3 className="h6">Phone</h3>
                  <div className="pb-2 ">
                    MohamadHossein.Nazari@yahoo.com
                  </div>
                </div>
              </div>
              <div className="col d-none d-print-block">
                <div className="mt-2">
                  <div>
                    <div className="mb-2">
                      <div className="text-dark">
                        <i className="fab fa-twitter mr-1"></i>
                        <span>https://twitter.com/</span>
                      </div>
                    </div>
                    <div className="mb-2">
                      <div className="text-dark">
                        <i className="fab fa-facebook mr-1"></i>
                        <span>https://www.facebook.com/</span>
                      </div>
                    </div>
                    <div className="mb-2">
                      <div className="text-dark">
                        <i className="fab fa-instagram mr-1"></i>
                        <span>https://www.instagram.com/edmond0_0nz</span>
                      </div>
                    </div>
                    <div className="mb-2">
                      <div className="text-dark">
                        <i className="fab fa-github mr-1"></i>
                        <span>https://github.com/edmondw2</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Persian;
